<template>
  <PageLayout>
    <div class="terms-page">
      <h1 class="header">Terms of Use</h1>
      <p class="header-title">These are the terms and conditions for use of the web sites of Mongolia.travel, an 
integral part of the Mongolia Ministry of Environment and Tourism. Contact 
details appear at the end of these Terms.</p>
      <p class="header-title">Contact us if you wish to advertise on the Site. We encourage such approaches.</p>
        <div class="use-of-website">
          <h6 class=" title-header">1. USE OF WEBSITE ON THESE CONDITIONS</h6>
          <p class="title">All use of the website www.mongolia.travel (“the Site”) is on the terms and conditions below.

If you do not agree to these conditions cease use of the Site immediately.
You may print and keep a copy of these terms. They are a legal agreement 
between you and us and can only be modified with our consent. We reserve the 
right to change the terms at our discretion by changing them on the Site. 
Goods or services advertised or offered for purchase on the Site (offers, 
campaigns, sponsored links and banners) are provided by our partners and 
comprise a contract between the user and our partner, not of the Mongolia 
Ministry of Environment and Tourism. 
Although of the Mongolia Ministry of 
Environment and Tourism uses its reasonable endeavors to ensure reputable 
suppliers are used on the Site it accepts no liability arising from supplies by such 
partners. All such business is on the terms and conditions of the partner and the
user which may be accessed from the Site.

The user is responsible for reviewing and accepting the terms and conditions of 
each partner before trading. Because the contract is made between the user 
and partner, any questions or complaints should be made to the partner direct.
         </p>
        </div>
        <div class="ACCURACY-OF-INFORMATION">
          <h6 class=" title-header">2. ACCURACY OF INFORMATION</h6>
          <p class="title">The Mongolia Ministry of Environment and Tourism (“we”) do our best to ensure 
all information on the Site is accurate.

If you find any inaccurate information on the Site let us know and we will correct 
it, where we agree, as soon as practicable.

You should independently verify any information before relying upon it.
We make no representations that information is accurate and up to date or 
complete and accept no liability for any loss or damage caused by inaccurate 
information. This Site gives a large amount of data and there will inevitably be 
errors in it, particularly because dates and times of events change and 
cancellations occur. You are advised to check directly with the organizers of the 
event concerned before making any arrangements.

We are a distributor (and not a publisher) of content supplied by third parties 
and users of the Site. Any opinions, advice, statements, services, offers, or other 
information or content expressed or made available by third parties, including 
information providers, or users, are those of the authors or distributors and not of 
us. We do not necessarily endorse nor are we responsible for the accuracy or 
reliability of any opinion, advice or statement made on the Site.
  
          </p>
        </div>
        <div class="OUR-LIABILITY">
          <h6 class=" title-header">3. OUR LIABILITY</h6>
          <p class="title">You agree to defend, indemnify and hold the Mongolia Ministry of Environment 
and Tourism harmless from and against any and all claims, liabilities, damages, 
losses and/or expenses, including, but not limited to, attorneys’ fees and costs, 
(all together the “Claims”) arising out of and/or in any way connected with your 
access to and/or use of the Site and/or Materials to the extend requested by the 
Mongolia Ministry of Environment and Tourism at its sole discretion.
         </p>
        </div>
        <div class="INTELLECTUAL-PROPERTY-RIGHTS-AND-LIMITED-LICENSE">
          <h6 class=" title-header">4. INTELLECTUAL PROPERTY RIGHTS AND LIMITED LICENSE</h6>
          <p class="title">All copyright and other intellectual property rights to the design, files, text, 
materials graphics, images, pictures, photos, sound files and/or all other 
information shall belong to by the Mongolia Ministry of Environment and Tourism 
and/or its licensors (all together the “Materials”). The property rights are limited 
to files, texts, materials, graphics, images, pictures, photos, and sound files hosted 
on the site and do not include such files aggregates through the ENWOKE 
technology.

The Mongolia Ministry of Environment and Tourism grants you a limited, non-
sublicensable right to access this Site, use the service(s) included in this Site and 
print the Materials for your personal, non-commercial, and informational use 
only. The Mongolia Ministry of Environment and Tourism reserves the right, 
without notice and in the Mongolia Ministry of Environment and Tourism’s sole 
discretion, to terminate your license to use the Site, and to block and/or prevent 
your future access to and use of the Site. However, your herein mentioned license 
grant does NOT include the right for you to: (i) publish, publicly perform and/or 
display, and/or distribute to any third party any Materials, including, but not 
limited to, reproduction on any computer network and/or broadcast or 
publications media; (ii) market, sell and/or make commercial use of the Site 
and/or any Materials; (iii) systematically collect and use of any data and/or 
content including the use of any data spiders, robots, and/or similar data 
gathering, mining or extraction methods; (iv) make derivative uses of the Site 
and/or the Materials; and/or (v) use, frame and/or utilize framing techniques to 
enclose any portion of this Site (including, but not limited to, the images found at 
this Site and/or any text or the layout/design of any page and/or form contained 
on a page).

Except for the limited license granted to you, you are not conveyed any other 
right and/or license by implication, estoppel, and/or otherwise in or under any 
patent, trademark, copyright, and/or proprietary right of the Mongolia Ministry of 
Environment and Tourism and/or any third party. Any unauthorized use of this 
Site will terminate the permission and/or license granted by these Terms of Use 
and may violate applicable law including, but not limited to, copyright and 
trademark laws, regulations and conventions.
         </p>
        <h6 class="title-header">ANY RIGHTS THAT ARE NOT EXPRESSLY GRANTED ARE RESERVED.</h6>
        
        </div>
        <div class="TRADEMARKS">
          <h6 class=" title-header">5. TRADEMARKS</h6>
          <p class="title">The “Mongolia Nomadic by Nature” logo is a trademark of the Mongolia Ministry 
of Environment and Tourism. All other names and/or logos mentioned on this Site 
are either trademarks, service marks or registered trademarks of the Mongolia 
Ministry of Environment and Tourism and/or its licensors and other partners, and 
may not be copied, imitated and/or used, in whole or in part, the Mongolia 
Ministry of Environment and Tourism’s prior written permission and/or that of the 
Mongolia Ministry of Environment and Tourism’s licensors.
         </p>
        </div>
        <div class="FEEDBACKS">
          <h6 class=" title-header">6. FEEDBACKS</h6>
          <p class="title">You acknowledge and agree that any questions, comments, suggestions, ideas, 
feedbacks and/or other information that you provide to Mongolia.travel 
(hereinafter referred to as the “Feedbacks”) are non-confidential and non-
proprietary. The Mongolia Ministry of Environment and Tourism shall have a right 
to the unrestricted use of any such Feedbacks for any purpose, commercial and/
or otherwise, without acknowledgment and/or compensation to you.
         </p>
        </div>
        <div class="MISCELLANEOUS">
          <h6 class=" title-header">7. MISCELLANEOUS</h6>
          <p class="title">The Mongolia Ministry of Environment and Tourism’s failure to exercise or enforce 
any right or provision of these Terms of Use will not constitute a waiver of such 
right or provision in that or any other instance. If, for any reason, any provision of 
these Terms of Use or portion thereof is rendered invalid and/or unenforceable, 
the remainder of these Terms of Use will remain in full force and effect and will be 
enforced to the maximum extent permissible so as to affect the intent of the 
parties. A court will substitute and/or rewrite any invalid and/or unenforceable 
term or condition to make such term or condition valid and enforceable. All 
communications and notices to be made or given pursuant to these Terms of 
Use and/or this Site will be in the English or Mongolian language. Any notice 
required to be given to the Mongolia Ministry of Environment and Tourism should 
be in writing and sent to its registered office at Government Building-2, United 
Nation’s Street 5/2, Ulaanbaatar 15160, Mongolia marked for the attention of the 
Marketing Department. Any notice to be given to you should be sent by email to 
the email address provided by you.
         </p>
        </div>
        <div class="GOVERNING LAW AND JURISDICTION">
          <h6 class=" title-header">8. GOVERNING LAW AND JURISDICTION</h6>
          <p class="title">You agree that all claims and disputes between the Mongolia Ministry of 
Environment and Tourism and you that arise out of or relate in any way to these 
Terms of Use and/or your use of this Site will be governed by the laws of 
Mongolia, without regard to the principles governing conflicts of any jurisdiction.
         </p>
        </div>
        <div class="COOKIES">
          <h6 class=" title-header">9. COOKIES</h6>
          <p class="title">To make this site work properly, we sometimes place cookies on your device. 
A cookie is a small text file that a website saves on your computer or mobile device 
when you visit the site. It enables the website to remember your actions and 
preferences (such as login, language, font size and other display preferences) 
over a period of time, so you don’t have to keep re-entering them whenever you 
come back to the site or browse from one page to another. Most big websites use 
cookies. Cookie data is anonymous and Google Analytics data collected from 
visitfinland.com is masked regarding the user’s IP address.</p>
          <h6 class="title-header">How do we use cookies?</h6>
<p class="title">We use cookies to collect information about users so that we can tailor your user 
experience, provide the best possible functionality and develop our services.
We can use both first-party and third-party cookies. Cookies are used among 
others to link social media functions and for online behavioural advertising.
</p>
          <h6 class="title-header">How to control cookies</h6>
          <p class="title">Consent is considered given if the browser is set to accept the use of 
cookies. This also applies if the browser is pre-set for acceptance.</p>
          <p class="title">You can control cookies as you wish – for details, see aboutcookies.org. You can 
delete all cookies that are already on your computer and you can set most 
browsers to prevent them from being placed. If you do this, however, you may 
have to manually adjust some preferences every time you visit a site and some 
services and functionalities may not work.
            
          </p>
        </div>
        <div class="FURTHER INFORMATION">
          <h6 class=" title-header">10. FURTHER INFORMATION</h6>
          <p class="title">Mongolia Ministry of Environment and Tourism
Government Building-2, United Nation’s Street 5/2
Ulaanbaatar 15160
Mongolia
Email: <a href="mailto:info@mongolia.travel" class="link">info@mongolia.travel</a>

         </p>
        </div>
    </div>
  </PageLayout>
</template>

<script>
import PageLayout from "@/layouts/PageLayout";

export default {
  name: "Terms of Use",
  components: {
    PageLayout
  }
};
</script>

<style lang="scss" scoped>
.terms-page {
  top:0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  
  .header{
    font-weight: bold;
    width:100%;
    font-size:57px;
  }
  .header-title{
    margin-top:20px;
    white-space: pre;
    text-align: left;
    margin: 20px 0px 16px;
  }

  
}
.terms-page{
  .title-header{
      margin-top:30px;
      font-weight: bold;
    }
    .title{
      margin-top:20px;
      white-space: pre;
      text-align: left;
      margin: 20px 0px 16px;
      .link{
        color: red;
      }
  }
}

</style>
